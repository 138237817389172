"use client";

import { Button } from "@cgc/ui/button";
import { Paragraph } from "@cgc/ui/typography";
import Script from "next/script";
import React, { PropsWithChildren } from "react";

declare global {
  interface Window {
    OptanonWrapper: any;
    Optanon: any;
    OneTrust: any;
  }
}

export const openCookieControlModal = () => {
  try {
    window.Optanon.ToggleInfoDisplay();
  } catch (err) {
    /* empty */
  }
};

type Props = {
  installAttentive?: boolean;
};

const OneTrust: React.FC<Props> = ({ installAttentive = true }: Props) => {
  if (process.env.NEXT_PUBLIC_ONETRUST_ENABLED !== "on") {
    return null;
  }

  return (
    <>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-domain-script="29d3ae5e-9fc3-4c29-ba1e-8b58c08f030a"
        onLoad={() => {
          window.OptanonWrapper = () => {
            if (installAttentive) {
              window.OneTrust.InsertScript(
                "https://cdn.attn.tv/gin/dtag.js",
                "head",
                null,
                null,
                "C0004",
              );
            }
          };
        }}
      />
    </>
  );
};

export default OneTrust;

export const CookieControlButton: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Button
    color="unstyled"
    className="pl-0 uppercase no-underline decoration-1 underline-offset-2 hover:text-black hover:underline"
    onClick={(event) => {
      event.preventDefault();
      openCookieControlModal();
    }}
    typoComponent={({ children }) => (
      <Paragraph size="p2" className="text-sm lg:text-sm">
        {children}
      </Paragraph>
    )}
  >
    {children}
  </Button>
);
